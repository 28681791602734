import { gql } from "@apollo/client";

export default gql`
  mutation CreateSchoolBulletinBoardQuestion(
    $question: String!
    $description: String!
    $schoolId: Float!
    $userId: String!
    $answers: [JSON]
  ) {
    postBulletinBoardQuestions(
      bulletinBoardQuestions2Input: {
        question: $question
        description: $description
        schoolId: $schoolId
        userId: $userId
        answers: $answers
      }
    ) {
      answers
    }
  }
`;
