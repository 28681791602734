import React from "react";
import { Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TransparentButton from "../../components/Buttons/TransparentButton";
import DangerButton from "../../components/Buttons/DangerButton";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "../../graphql/mutations";
import { GET_LICENSE_KEYS } from "../../graphql/queries";
import { parseErrorMessage } from "../../utils/parseGraphQLErrors";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: 40,
    width: 500,
  },
  title: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    fontWeight: 600,
    textAlign: "center",
  },
  text: {
    marginTop: 50,
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
  },
  buttonContainer: {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const DeleteUserModal = ({ onDelete, selectedUserIdForDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deleteUserMutation] = useMutation(DELETE_USER, {
    refetchQueries: [GET_LICENSE_KEYS, "GetLicenseKeys"],
  });

  const deleteUser = async () => {
    console.log(selectedUserIdForDelete);
    try {
      await deleteUserMutation({
        variables: {
          userId: selectedUserIdForDelete,
        },
      });
    } catch (error) {
      console.log(parseErrorMessage(error));
    }
    onDelete();
  };

  return (
    <Box className={classes.modalContainer}>
      <Typography className={classes.title}>{t("users.areYouSure")}</Typography>
      <Typography className={classes.text}>
        {t("users.confirmationText")}
      </Typography>
      <Box className={classes.buttonContainer}>
        <TransparentButton onClick={onDelete}>
          {t("users.cancel")}
        </TransparentButton>
        <DangerButton onClick={deleteUser}>
          {t("users.deleteStudent")}
        </DangerButton>
      </Box>
    </Box>
  );
};

DeleteUserModal.propTypes = {
  onDelete: PropTypes.func,
  selectedUserIdForDelete: PropTypes.string,
};

export default DeleteUserModal;
