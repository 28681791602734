import { Box, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  menuContainer: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 50,
    right: 10,
    backgroundColor: Colors.COLOR_WHITE,
    color: Colors.COLOR_LABEL,
    border: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
  },
  menuItem: {
    padding: "15px 30px",
  },
  menuButton: {
    fontSize: Fonts.SIZES.MEDIUM_DESCRIPTION_TEXT,
    color: Colors.COLOR_LABEL,
  },
}));

const UserMenu = ({ logOut }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.menuContainer}>
      <Box className={classes.menuItem}>
        <ButtonBase
          disableRipple={true}
          className={classes.menuButton}
          onClick={logOut}
        >
          {t("menu.logout")}
        </ButtonBase>
      </Box>
    </Box>
  );
};

UserMenu.propTypes = {
  logOut: PropTypes.func.isRequired,
};

export default UserMenu;
