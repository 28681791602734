import PropTypes from "prop-types";

const Home = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.861 24.759"
      fill={color}
    >
      <path
        className="a"
        d="M10.692,24.732H4.99a1.316,1.316,0,0,1-1.314-1.314V13.56H1.488a1.314,1.314,0,0,1-.9-2.27L12.2.331a1.314,1.314,0,0,1,1.8,0L25.618,11.29a1.315,1.315,0,0,1-.9,2.27H22.527v9.858a1.315,1.315,0,0,1-1.314,1.314H15.6V18.067a1.315,1.315,0,0,0-1.314-1.314H12.006a1.315,1.315,0,0,0-1.314,1.314v6.664h0Z"
        transform="translate(-0.172 0.027)"
      />
    </svg>
  );
};

Home.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Home;
