import { gql } from "@apollo/client";

export default gql`
  query GetBulletinBoardResults($schoolId: Float!) {
    bulletinBoardQuestionsResult(schoolId: $schoolId) {
      id
      question
      totalAnswers
      toDate
      fromDate
      results {
        id
        answer
        numberOfAnswers
        percentageOfVotes
        totalAnswers
      }
    }
  }
`;
