import PropTypes from "prop-types";
import { Tab, Tabs } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";

const useStyles = makeStyles((theme) => ({
  tabs: {
    textTransform: "none",
  },
  tab: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    opacity: 0.5,
    textTransform: "none",
    padding: 0,
    minWidth: 0,
    "&:not(:first-child)": {
      marginLeft: 50,
    },
  },
  tabWrapper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  tabSelected: {
    color: Colors.COLOR_BLACK,
  },
  tabIndicator: {
    backgroundColor: Colors.COLOR_PRIMARY_BUTTON,
    height: 3,
  },
}));

const CustomTab = ({ tabLabels, activeTab, onTabChange }) => {
  const classes = useStyles();
  return (
    <Tabs
      value={activeTab}
      onChange={onTabChange}
      classes={{ root: classes.tabs }}
      TabIndicatorProps={{
        className: classes.tabIndicator,
      }}
    >
      {tabLabels.map((label) => {
        return (
          <Tab
            key={label}
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label={label}
            disableRipple={true}
          />
        );
      })}
    </Tabs>
  );
};

CustomTab.propTypes = {
  tabLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeTab: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default CustomTab;
