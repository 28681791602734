import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SurveyTableHeader from "./SurveyTableHeader";
import SurveyTableRow from "./SurveyTableRow";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 30,
    marginBottom: 50,
  },
}));

const SurveyTable = ({ results }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <SurveyTableHeader />
      {results?.map((result, index) => {
        return (
          <SurveyTableRow
            questionNumber={index + 1}
            key={result.id}
            question={result.question}
            questionResults={result.answerCount}
          />
        );
      })}
    </Box>
  );
};

SurveyTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      answerCount: PropTypes.arrayOf(
        PropTypes.shape({
          answerType: PropTypes.string,
          numberOfAnswers: PropTypes.number,
        })
      ),
    })
  ),
};

export default SurveyTable;
