import PropTypes from "prop-types";

const Plus = (props) => {
  const { height, width, color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20.713 22"
      fill={color}
    >
      <path
        d="M13.239-5.9h8.426v3.632H13.239V7.278H9.379V-2.269H.952V-5.9H9.379v-8.821h3.86Z"
        transform="translate(-0.952 14.722)"
      />
    </svg>
  );
};

Plus.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default Plus;
