import Sidebar from "../Sidebar";
import Header from "../Header";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const Layout = ({ children, onLogOut }) => {
  const { pathname } = useLocation();
  return (
    <>
      <Header onLogOut={onLogOut} />
      {pathname !== "/" && <Sidebar />}
      {children}
    </>
  );
};

Layout.propTypes = {
  onLogOut: PropTypes.func.isRequired,
};

export default Layout;
