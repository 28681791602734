import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Colors } from "../../styles";
import evaluationValues from "../../constants/evaluationValues";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    padding: 20,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "50px 0",
  },
  titleText: {
    fontSize: 28,
  },
  tableHeaderContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fcfcfc",
    padding: "10px 10px 10px 40px",
    "& p": {
      fontSize: 11,
    },
  },
  firstColumn: {
    width: 100,
  },
  column: {
    width: "calc(70% - 100px)",
  },
  lastColumn: {
    width: "calc(30% - 100px)",
  },
  tableRowContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 10px 10px 40px",
    backgroundColor: "#fcfcfc",
    "&:nth-child(even)": {
      backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
    },
    "& p": {
      fontSize: 11,
    },
  },
  tableRowContainerOdd: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
    "& p": {
      fontSize: 11,
    },
  },
  resultText: {
    "&:not(:first-child)": {
      marginTop: 5,
    },
  },
});

const MonthlyEvaluationPDF = ({ results, month }) => {
  const { t } = useTranslation();
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>
            {t("monthlyEvaluation.pdfHeader")}
            {month}
          </Text>
        </View>
        <View style={styles.tableHeaderContainer}>
          <Text style={styles.firstColumn}>
            {t("monthlyEvaluation.tableNumber")}
          </Text>
          <Text style={styles.column}>
            {t("monthlyEvaluation.tableQuestion")}
          </Text>
          <Text style={styles.lastColumn}>
            {t("monthlyEvaluation.tableAnswer")}
          </Text>
        </View>
        {results?.map((result, index) => {
          return (
            <View
              wrap={false}
              key={uuidv4()}
              style={
                (index + 1) % 2 === 1
                  ? styles.tableRowContainerOdd
                  : styles.tableRowContainer
              }
            >
              <Text style={styles.firstColumn}>{index + 1}</Text>
              <Text style={styles.column}>{result.question}</Text>
              <View style={styles.lastColumn}>
                {result?.answerCount.map((answers) => {
                  return (
                    <Text key={uuidv4()}>
                      {t(evaluationValues[answers.answerType])} - (
                      {answers.numberOfAnswers}{" "}
                      {t("monthlyEvaluation.resultText")})
                    </Text>
                  );
                })}
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

MonthlyEvaluationPDF.propTypes = {
  month: PropTypes.string,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      answerCount: PropTypes.arrayOf(
        PropTypes.shape({
          answerType: PropTypes.string,
          numberOfAnswers: PropTypes.number,
        })
      ),
    })
  ),
};

export default MonthlyEvaluationPDF;
