import { Grid, InputBase, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "../../../styles";
import Fonts from "../../../styles/Fonts";
import PropTypes from "prop-types";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  inputRow: {
    marginTop: (props) => (props.rowMargin >= 0 ? props.rowMargin : 40),
  },
  input: {
    backgroundColor: Colors.COLOR_WHITE,
    border: `1px solid ${Colors.COLOR_GRAY}`,
    padding: "10px",
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    "& input": {
      padding: 0,
    },
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_BLACK,
    marginBottom: 10,
  },
}));

const BaseInputField = (props) => {
  const classes = useStyles(props);
  const {
    id,
    multiline,
    label,
    rows,
    disabled,
    value,
    onChange,
    placeholder,
    inputClass,
    labelClass,
  } = props;
  return (
    <Grid container direction="column" className={classes.inputRow}>
      {label && (
        <InputLabel
          htmlFor={id}
          className={classNames(classes.inputLabel, labelClass)}
        >
          {label}
        </InputLabel>
      )}
      <InputBase
        className={classNames(classes.input, inputClass)}
        id={id}
        multiline={multiline}
        rows={rows}
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={id}
        placeholder={placeholder}
      />
    </Grid>
  );
};

BaseInputField.propTypes = {
  id: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  label: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  rowMargin: PropTypes.number,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
};

export default BaseInputField;
