import React, { useContext, useEffect, useState } from "react";
import bankID from "../../assets/images/bankid@2x.png";
import bankIDRGB from "../../assets/images/bankid_low_rgb@2x.png";
import error from "../../assets/images/error_icn.svg";
import check from "../../assets/images/check.svg";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Link,
  Typography,
} from "@material-ui/core";
import { Colors, Fonts } from "../../styles";
import BaseInputField from "../../components/Inputs/BaseInput";
import { useMutation } from "@apollo/client";
import { LOGIN_BANK_ID } from "../../graphql/mutations";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 100px)",
  },
  title: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    marginBottom: 75,
  },
  inputContainer: {
    width: 400,
  },
  inputLabel: {
    fontSize: Fonts.SIZES.LOGIN_LABEL,
  },
  input: {
    border: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    fontSize: Fonts.SIZES.LOGIN_LABEL,
    paddingTop: 9,
  },
  loginButton: {
    padding: "40px 70px",
    marginTop: 50,
    borderRadius: 50,
  },
  loginHelpText: {
    marginTop: 100,
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
  },
  supportLink: {
    marginTop: 20,
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    color: Colors.COLOR_PRIMARY_BUTTON,
    paddingBottom: 5,
    borderBottom: `2px solid ${Colors.COLOR_PRIMARY_BUTTON}`,
    "&:hover": {
      textDecoration: "none",
    },
  },
  cancelButton: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    textTransform: "none",
    color: Colors.COLOR_PRIMARY_BUTTON,
  },
  buttonImage: {
    width: 50,
    height: 44,
    marginLeft: 20,
  },
  bankIdHeaderText: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    marginBottom: 75,
  },
  bankIdImageLarge: {
    height: 108,
    width: 108,
    marginBottom: 75,
  },
  loadingIndicatorContainer: {
    marginBottom: 125,
  },
  progress: {
    color: Colors.COLOR_PRIMARY_BUTTON,
  },
  successIcon: {
    width: 120,
    height: 120,
  },
  redirectText: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    marginTop: 75,
    marginBottom: 75,
  },
}));

const Login = ({ onLoginComplete }) => {
  const classes = useStyles();
  const [ssn, setSsn] = useState("");
  const [loginStep, setLoginStep] = useState({
    status: 0,
  });
  const { t } = useTranslation();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [loginBankId, { data: loginResponse, error: loginError }] =
    useMutation(LOGIN_BANK_ID);

  const onLogin = async () => {
    setLoginStep({ status: 1 });
    try {
      await loginBankId({
        variables: {
          personalNumber: ssn,
        },
      });
      history.push("/home");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loginError && loginStep.status === 1) {
      setLoginStep({ status: 4 });
    }
  }, [loginError, loginStep.status]);

  useEffect(() => {
    if (loginResponse && loginStep.status === 1) {
      onLoginComplete(loginResponse?.postUserAdminBankId);
    }
  }, [history, loginResponse, loginStep.status, onLoginComplete]);

  useEffect(() => {
    document.title = t("login.title");
  }, [t]);

  const onCancel = () => {
    setLoginStep({ status: 0 });
  };

  const goToLogin = () => {
    setLoginStep({ status: 0 });
  };

  return (
    <>
      {authContext ? (
        <Redirect to="/home" />
      ) : (
        <Box>
          {loginStep.status === 0 ? (
            <Box className={classes.container}>
              <Typography className={classes.title}>
                {t("login.welcomeMessage")}
              </Typography>
              <Box className={classes.inputContainer}>
                <BaseInputField
                  id="ssn"
                  onChange={(event) => setSsn(event.target.value)}
                  value={ssn}
                  placeholder=""
                  label={t("login.inputLabel")}
                  labelClass={classes.inputLabel}
                  inputClass={classes.input}
                />
              </Box>
              <PrimaryButton onClick={onLogin} className={classes.loginButton}>
                {t("login.loginButton")}
                <img
                  src={bankID}
                  className={classes.buttonImage}
                  alt="bankID-icon"
                />
              </PrimaryButton>
              <Typography className={classes.loginHelpText}>
                {t("login.supportText")}
              </Typography>
              <Link
                className={classes.supportLink}
                href="mailto:jonas@twoact.com"
              >
                {t("login.supportLink")}
              </Link>
            </Box>
          ) : null}
          {loginStep.status === 1 ? (
            <Box className={classes.container}>
              <Typography className={classes.bankIdHeaderText}>
                {t("login.bankIdStartMessage")}
              </Typography>
              <img
                src={bankIDRGB}
                className={classes.bankIdImageLarge}
                alt="bankID-icon"
              />
              <Box className={classes.loadingIndicatorContainer}>
                <CircularProgress
                  size={100}
                  classes={{ colorPrimary: classes.progress }}
                />
              </Box>
              <ButtonBase onClick={onCancel} className={classes.cancelButton}>
                {t("login.cancel")}
              </ButtonBase>
            </Box>
          ) : null}
          {loginStep.status === 4 ? (
            <Box className={classes.container}>
              <Typography className={classes.bankIdHeaderText}>
                {t("login.loginFailed")}
              </Typography>
              <img
                src={error}
                className={classes.successIcon}
                alt="error-icon"
              />
              <Typography className={classes.redirectText}>
                {t("login.redirectMessage")}
              </Typography>
              <ButtonBase
                onClick={goToLogin}
                className={classes.cancelButton}
                disableRipple={true}
              >
                {t("login.login")}
              </ButtonBase>
            </Box>
          ) : null}
          {loginStep.status === 2 ? (
            <Box className={classes.container}>
              <Typography className={classes.bankIdHeaderText}>
                {t("login.loginSuccess")}
              </Typography>
              <img
                src={check}
                className={classes.successIcon}
                alt="success-icon"
              />
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

export default Login;
