import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import Stats from "../../components/Icons/Stats";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import WhistleBlower from "../../components/Icons/WhistleBlower";
import People from "../../components/Icons/People";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  home: {
    height: "calc(100vh - 100px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 250,
  },
  welcomeText: {
    fontSize: Fonts.SIZES.EXTRA_TITLE_TEXT,
    marginBottom: 75,
    textAlign: "center",
  },
  homeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 100,
  },
  itemRow: {
    display: "flex",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.COLOR_WHITE,
    padding: 50,
    border: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    borderRadius: 6,
    "&:nth-child(2)": {
      margin: "0 30px",
    },
  },
  itemHeading: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
    margin: "40px 0",
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("home.title");
  }, [t]);

  return (
    <Box className={classes.home}>
      <Box className={classes.homeContainer}>
        <Typography className={classes.welcomeText}>
          {t("home.welcomeMessage")}
        </Typography>
        <Box className={classes.itemRow}>
          <Box className={classes.item}>
            <Stats height={60} width={60} color={Colors.COLOR_PRIMARY_BUTTON} />
            <Typography className={classes.itemHeading}>
              {t("home.monthlyEvaluation")}
            </Typography>
            <PrimaryButton onClick={() => history.push("/survey")}>
              {t("home.buttonText")}
            </PrimaryButton>
          </Box>
          <Box className={classes.item}>
            <WhistleBlower
              height={60}
              width={60}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.itemHeading}>
              {t("home.bulletin")}
            </Typography>
            <PrimaryButton onClick={() => history.push("/bulletin")}>
              {t("home.buttonText")}
            </PrimaryButton>
          </Box>
          <Box className={classes.item}>
            <People
              height={60}
              width={60}
              color={Colors.COLOR_PRIMARY_BUTTON}
            />
            <Typography className={classes.itemHeading}>
              {t("home.users")}
            </Typography>
            <PrimaryButton onClick={() => history.push("/users")}>
              {t("home.buttonText")}
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
