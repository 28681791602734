import LinearProgressWithLabel from "./index";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Colors, Fonts } from "../../styles";
import { Box, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: Colors.COLOR_WHITE,
    padding: 20,
    border: `1px solid ${Colors.COLOR_LIGHT_GRAY}`,
    borderRadius: 6,
    marginTop: 40,
    flex: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "&:nth-child(odd)": {
      marginRight: 40,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  title: {
    fontSize: Fonts.SIZES.SUBTITLE_TEXT,
    marginBottom: 25,
  },
  numberOfAnswers: {
    fontSize: Fonts.SIZES.LARGE_DESCRIPTION_TEXT,
  },
  dateText: {
    fontSize: Fonts.SIZES.DATE_TEXT,
    fontStyle: "italic",
  },
  bottomRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 70,
  },
  progressBarContainer: {
    marginTop: 10,
  },
}));
const ProgressCard = ({
  question,
  answers,
  toDate,
  fromDate,
  numberOfAnswers,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.card}>
      <Box>
        <Typography className={classes.title}>{question}</Typography>
        {answers.map((answer) => {
          return (
            <Box className={classes.progressBarContainer} key={uuidv4()}>
              <LinearProgressWithLabel
                value={answer.percentage}
                label={answer.answer}
              />
            </Box>
          );
        })}
      </Box>
      <Box className={classes.bottomRowContainer}>
        <Typography className={classes.numberOfAnswers}>
          {t("bulletin.numberOfAnswers")} {numberOfAnswers}
        </Typography>
        {fromDate && toDate && (
          <span className={classes.dateText}>
            {`${format(new Date(fromDate), "y-MM-dd")} - 
            ${format(new Date(toDate), "y-MM-dd")}`}
          </span>
        )}
      </Box>
    </Box>
  );
};

ProgressCard.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      percentage: PropTypes.number,
    })
  ).isRequired,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  numberOfAnswers: PropTypes.number.isRequired,
};

export default ProgressCard;
