import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import UserTableHeader from "./UserTableHeader";
import UserTableRow from "./UserTableRow";
import PropTypes from "prop-types";
import { Colors, Fonts } from "../../styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: 30,
    marginBottom: 50,
  },
  emptyStateContainer: {
    padding: "10px 10px 10px 40px",
    backgroundColor: Colors.COLOR_TABLE_ROW_ODD,
  },
  text: {
    fontSize: Fonts.SIZES.TABLE_HEADING_TEXT,
  },
}));

const UserTable = ({ results, onDeleteClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.tableContainer}>
      <UserTableHeader />
      {results.length === 0 && (
        <Box className={classes.emptyStateContainer}>
          <Typography className={classes.text}>
            {t("users.noResults")}
          </Typography>
        </Box>
      )}
      {results?.map((result) => {
        return (
          <UserTableRow
            key={result.id}
            email={result.email}
            licenseKey={result.licenseKey}
            registered={result.registered}
            onDeleteClick={() => onDeleteClick(result.userId)}
          />
        );
      })}
    </Box>
  );
};

UserTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      userId: PropTypes.string,
      licenseKey: PropTypes.string,
      email: PropTypes.string,
      registered: PropTypes.bool,
    })
  ).isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default UserTable;
