import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import localStorageKeys from "../constants/localStorageKeys";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../utils/localStorageUtils";
import { POST_REFRESH_TOKEN } from "./mutations";
import { isAfter } from "date-fns";

const uri = process.env.REACT_APP_GRAPHQL_ENDPOINT;

const mainLink = createHttpLink({
  uri,
  credentials: "same-origin",
  fetch,
});

const refreshClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: mainLink,
});

const contextLink = setContext(async (_, { headers }) => {
  const token = getLocalStorageValue(localStorageKeys.ACCESS_TOKEN, {
    accessToken: null,
  });
  const tokenHasExpired = isAfter(new Date(), new Date(token.expiresAt));
  if (tokenHasExpired) {
    console.log("Token has expired, refreshing...");
    const refreshData = await refreshClient.mutate({
      mutation: POST_REFRESH_TOKEN,
      variables: {
        refreshToken: token.refreshToken,
      },
    });
    setLocalStorageValue(localStorageKeys.ACCESS_TOKEN, {
      ...token,
      accessToken: refreshData.data?.postTokenRefresh.accessToken,
      expiresAt: refreshData.data?.postTokenRefresh.expiresAt,
    });
  }
  const { accessToken } = getLocalStorageValue(localStorageKeys.ACCESS_TOKEN, {
    accessToken: null,
  });
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const link = contextLink.concat(mainLink);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default apolloClient;
